<template>
  <div>
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">락업 플랜 추가</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>사용자 번호 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="number" class="form-control" :value="userIdx" disabled style="background: #6c757d;"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수량 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="number" class="form-control" v-model="amtToLockup"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>지급 수량</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" :value="shareAmount" disabled style="background: #6c757d;"/>
                    <div class="input_placeholder_1">
                      <span>수량을 입력하면 지급 비율에 따라 자동 계산됩니다.</span>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>락업 플랜*</strong>
                  </div>
                  <div class="col-sm-6">
                    <select class="form-control" v-model="selectedPlanIdx">
                      <option v-for="lockupPlan in lockupPlans" :key="lockupPlan.lockupPlanIdx" :value="lockupPlan.lockupPlanIdx">{{ lockupPlan.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>락업기간</strong>
                  </div>
                  <div class="col-sm-6">
                    <span class="form-control" style="background: #6c757d;">{{ lockupPlanPeriod }}일</span>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>지급비율 *</strong>
                  </div>
                  <div class="col-sm-3">
                    <span class="form-control" style="background: #6c757d;">{{ 100/selectedRatio }} %</span>
                  </div>
                  <div class="col-sm-3">
                    <span class="form-control" style="background: #6c757d;">{{ latency }} Day</span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="openConfirm">추가</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>락업플랜</th>
                    <th>락업잔고</th>
                    <th>락업만료일</th>
                    <th>지급 예정 잔고</th>
                    <th>지급 완료 잔고</th>
                    <th>지급 비율</th>
                    <th>진행상태</th>
                    <th>생성일</th>
                    <th>락업 플랜 해지</th>
                    <th>지급내역</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.lockupPlanName }}</td>
                    <td style="text-align: right">{{ item.lockupBalance }}</td>
                    <td style="text-align: center">{{ item.lockupExpiryDate | moment('YYYY-MM-DD') }}</td>
                    <td style="text-align: right">{{ item.remain }}</td>
                    <td style="text-align: right">{{ item.lockupBalance - item.remain }}</td>
                    <td style="text-align: center; white-space: nowrap">{{ `${100/item.ratio}% / ${item.latency}Day` }}</td>
                    <td style="text-align: center">
                      <span>{{ trans(item.state) }}</span>
                    </td>
                    <td style="text-align: center">{{ item.createdAt | moment('YYYY-MM-DD') }} {{ item.createdAt | moment('HH:mm:ss') }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" v-show="item.state === 'LOCK' || item.state === 'PROCESSING'" @click="terminatePlan(item.lockupIdx)">해지하기</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="$router.push({ name: 'LockupHistoryPayment', params: { item: item } })">내역보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Big from "big-js"
import Confirm from "@/components/common/Confirm";
import Pagination from "../common/Pagination"

export default {
  name: 'UserAddLockupPlan',
  data() {
    return {
      userIdx: this.$route.params.userIdx,
      amtToLockup: 0,
      lockupPlans: [],
      selectedPlanIdx: 0,
      selectedRatio: 0,
      shareAmount: 0,
      lockupPlanPeriod: 0,
      latency: 0,
      page: 1,
      size: 5,
      items: [],
    }
  },

  components: {
    Pagination,
    Confirm,
  },

  created() {
    if(this.userIdx === undefined) {
      this.$router.back()
    }

    this.$get(`${this.$LOCKUP_PLAN_LIST}?page=1&limit=100`, this.$options.name + 'getLockupPlanList', true, (result) => {
      console.log(result.data)
      this.lockupPlans = result.data
      this.initValue()
    }, (result) => {
      console.log(result)
    })
    this.getList(1)
  },

  methods: {
    terminatePlan(lockupIdx) {
      this.$refs.Confirm.showDialog(`락업 플랜을 해지 하시겠습니까?<br>※ 락업 종료 후 지급 중인 경우 금일 이후로 지급이 중단됩니다.`, () => {
        let formData = new FormData()
        formData.append('lockupIdx', lockupIdx)

        this.$post(this.$LOCKUP_PLAN_CANCEL, this.$options.name + 'terminatePlan', formData, true, (result) => {
          console.log(result)
          this.notify('success', '해지되었습니다.')
          this.getList(1)
        }, (result) => {
          console.log(result)
        })
      })
    },
    trans(value) {
      if (value === 'LOCK') {
        return '락업중'
      } else if (value === 'PROCESSING') {
        return '지급중'
      } else if (value === 'CANCEL') {
        return '취소'
      } else if (value === 'DONE') {
        return '지급완료'
      }
    },
    getList(page) {
      if(this.page === undefined) this.page = 1
      this.$get(`${this.$LOCKUP_HISTORY_USER}?userIdx=${this.userIdx}&page=${page}&limit=${this.size}`, this.$options.name + '_getUserPlanList', true, (result) => {
        this.page = page
        this.total = result.total
        this.items = result.data
        console.log(page)
        console.log(result.data)
        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
      }, (result) => {
        console.log(result)
      })
    },

    initValue() {
      this.selectedPlanIdx = this.lockupPlans[0].lockupPlanIdx
      this.selectedRatio = this.lockupPlans[0].ratio
      this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
    },

    calShareAmt(amt, ratio) {
      Big.DP = 9
      let amtBig = new Big(amt)
      let ratioBig = new Big(ratio)

      let shareAmt = amtBig.div(ratioBig)
      return shareAmt
    },

    openConfirm() {
      Big.DP = 9
      let amtToLockupBig = new Big(this.amtToLockup)
      let oneBig = new Big(1)
      this.amtToLockup = amtToLockupBig.div(oneBig)

      this.$refs.Confirm.showDialog(`락업수량: ${this.amtToLockup}<br/>지급수량: ${this.shareAmount}`, () => {
        this.addUserLockupPlan()
      })
    },

    addUserLockupPlan() {
      if(!this.amtToLockup) {
        this.notify('error', '수량을 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('userIdx', this.userIdx)
      formData.append('amount', this.amtToLockup)
      formData.append('shareAmount', this.shareAmount)
      formData.append('lockupPlanIdx', this.selectedPlanIdx)

      this.$post(this.$LOCKUP_PLAN_ADD, this.$options.name + '_setData', formData, true, (result) => {
        this.notify('success', result.message)
        this.initValue()
        this.getList(1)
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  watch: {
    'amtToLockup'() {
      if(!this.amtToLockup) {
        return
      }
      this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
    },
    'selectedPlanIdx'() {
      if(!this.selectedPlanIdx) {
        return
      }
      this.lockupPlans.forEach(plan => {
        // console.log(plan)
        if(plan.lockupPlanIdx === this.selectedPlanIdx) {
          this.selectedRatio = plan.ratio
          this.shareAmount = this.calShareAmt(this.amtToLockup, this.selectedRatio)
          this.latency = plan.latency
          this.lockupPlanPeriod = plan.period
        }
      })
    },
  }
}
</script>

<style>
th {
  text-align: center;
  /*vertical-align: middle;*/
  word-break: keep-all;
}
td {
  vertical-align: middle;
  word-break: keep-all;
}
.input_placeholder_1{
  position: absolute;
  top: 16%;
  right: 3%;
}
.table thead th {
  vertical-align: middle;
}
.table td {
  vertical-align: middle;
}
</style>

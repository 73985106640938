<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">사용자</h4>
            <span> 데이터베이스에 기록된 사용자 목록을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">일반 사용자</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <UserDetailInfo
                      :userIdx="userIdx"
                    />
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <div class="text-center">
                  <button type="button" class="btn btn-primary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import UserDetailInfo from "@/components/user/detail/UserDetailInfo"

export default {
  name: 'UserDetail',
  components: {
    UserDetailInfo,
  },

  data() {
    return {
      userIdx: this.$route.params.userIdx,
    }
  },

  created() {
    if(!this.userIdx) {
      this.$router.back()
      return
    }
  },

}
</script>

<style>
</style>

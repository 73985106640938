<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">지급 내역</h4>
            <span>락업 지급 내역을 확인합니다.</span>
          </div>
        </div>
<!--        <div class="row mb-2">-->
<!--          <div class="col-sm-12">-->
<!--            <h5 class="mb-2">필터</h5>-->
<!--            <div class="row">-->
<!--              <select class="form-control w-auto mr-1" v-model="selectedState">-->
<!--                <option value="">진행상태</option>-->
<!--                <option value="cancel">취소</option>-->
<!--                <option value="pending">진행</option>-->
<!--                <option value="completed">완료</option>-->
<!--              </select>-->
<!--              <select class="form-control w-auto mr-1" v-model="selectedSearchType">-->
<!--                <option value="email">이메일</option>-->
<!--              </select>-->
<!--              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">-->
<!--              <button class="btn btn-primary" @click="getList(page)">검색</button>-->
<!--            </div>-->
<!--          </div>&lt;!&ndash; /.col &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.row &ndash;&gt;-->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>번호</th>
                    <th>이메일</th>
                    <th>락업 잔고</th>
                    <th>락업 만료일</th>
                    <th>지급 수량</th>
                    <th>지급 일자</th>
                    <th>지급 합계</th>
                  </tr>
                  </thead>
                  <tbody v-if="payments.length > 0">
                  <tr v-for="(payment, index) in payments" :key="index">
                    <td>{{ payment.transferIdx }}</td>
                    <td>{{ payment.owner }}</td>
                    <td>{{ item.lockupBalance }}</td>
                    <td>{{ item.lockupExpiryDate | moment('YYYY-MM-DD') }}</td>
                    <td>{{ payment.amount }}</td>
                    <td>{{ payment.date | moment('YYYY-MM-DD') }}</td>
                    <td>{{ Number(payment.amount) - Number(payment.feeAmount) }}</td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"

export default {
  name: 'LockupHistoryPayment',

  components: {
    Pagination,
  },

  data() {
    return {
      // selectedState: '',
      // selectedSearchType: 'email',
      // searchKeyword: '',
      size: '10',
      page: 0,
      total: 0,
      payments: [],
      item: this.$route.params.item
    }
  },

  created() {
    if(!this.item) {
      this.$router.back()
      return
    }

    // this.$root.$emit(this.$EVENT_LNB_CHANGE, 'LockupHistory', this.$options.name)

    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    // let ss = this.$route.query.ss
    // if(typeof(ss) !== 'undefined') {
    //   this.selectedState = ss
    // }
    //
    // let st = this.$route.query.st
    // if(typeof(st) !== 'undefined') {
    //   this.selectedSearchType = st
    // }
    //
    // let sk = this.$route.query.sk
    // if(typeof(sk) !== 'undefined') {
    //   this.searchKeyword = sk
    // }

    this.getList(page)
  },

  methods: {
    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('lockupIdx', this.item.lockupIdx)
      // params.append('userIdx', this.item.userIdx)

      this.$get(this.$LOCKUP_HISTORY_PAYMENT + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total

        try {
          this.$refs.Pagination.init(this.$options.name, {}, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }

        this.payments = result.data
      }, (result) => {
        this.httpError(result)
      })
    },

  },
  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}
</script>

<style>
</style>

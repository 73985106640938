<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <!--            <h4 class="m-0">락업 플랜 추가</h4>-->
            <!--            <span> 데이터베이스에 기록된 관리자 목록을 확인합니다.</span>-->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">외부 출금 수수료</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수수료 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <input type="number" class="form-control" v-model="formData.fee"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>수수료 타입 *</strong>
                  </div>
                  <div class="col-sm-5">
                    <select class="form-control" v-model="formData.feeType">
                      <option :value="true">퍼센트(%)</option>
                      <option :value="false">고정값(수량)</option>
                    </select>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="setFee">설정</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CurrencyExternal',
  data() {
    return {
      formData: {
        fee: 0,
        feeType: 'true',
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'currency', this.$options.name)

    this.$get(this.$LOCKUP_PLAN_FEE_INFO, this.$options.name + '_setData', true, (result) => {
      let data = result.data[0]
      this.formData.fee = data.externalFee
      this.formData.feeType = data.externalFeeType.toString()
    }, (result) => {
      this.httpError(result)
    })
  },

  methods: {
    setFee() {
      let formData = new FormData()
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        formData.append(key, (value+'').trim())
      })

      this.$post(this.$LOCKUP_PLAN_FEE_EXTERNAL, this.$options.name + '_setData', formData, true, () => {
        this.notify('success', '설정되었습니다.')
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
</style>

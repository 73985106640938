<template>
  <div>
    <Confirm ref="Confirm"></Confirm>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">락업 목록</h4>
            <span> 데이터베이스에 기록된 락업 목록을 확인합니다.</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <select class="form-control w-auto mr-1" v-model="selectedState">
                <option value="">진행 상태</option>
                <option value="LOCK">락업중</option>
                <option value="PROCESSING">지급중</option>
                <option value="DONE">지급완료</option>
                <option value="CANCEL">취소</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="selectedSearchType">
                <option value="EMAIL">이메일</option>
              </select>
              <input type="text" class="form-control w-auto mr-1" placeholder="검색어" v-model="searchKeyword">
              <button class="btn btn-primary" @click="getList(page)">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>고유번호</th>
                    <th>이메일</th>
                    <th>락업 플랜</th>
                    <th>락업 잔고</th>
                    <th>락업 만료일</th>
                    <th>지급 예정 잔고</th>
                    <th>지급 완료 잔고</th>
                    <th>지급 비율</th>
                    <th>진행 상태</th>
                    <th>생성일</th>
                    <th>락업 플랜 추가</th>
                    <th>락업 플랜 해지</th>
                    <th>지급 내역</th>
                  </tr>
                  </thead>
                  <tbody v-if="items.length > 0">
                  <tr v-for="(item) in items" :key="item.lockupIdx">
                    <td>{{ item.lockupIdx }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-left" style="white-space: nowrap">{{ item.lockupPlanName }}</td>
                    <td class="text-right">{{ item.lockupBalance }}</td>
                    <td>{{ item.lockupExpiryDate | moment('YYYY-MM-DD') }}</td>
                    <td class="text-right">{{ item.remain }}</td>
                    <td class="text-right">{{ item.lockupBalance - item.remain }}</td>
                    <td style="white-space: nowrap">{{ `${100/item.ratio}% / ${item.latency}Day` }}</td>
                    <td>{{ trans(item.state) }}</td>
                    <td>{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="addUserLockupPlan(item)">추가하기</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" v-show="item.state === 'LOCK' || item.state === 'PROCESSING'" @click="terminatePlan(item.lockupIdx)">해지하기</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="$router.push({ name: 'LockupHistoryPayment', params: { item: item } })">내역보기</span>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="10" style="text-align: center;">관련된 내역이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row justify-content-center">
                <div></div>
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "../common/Pagination"
import Confirm from "@/components/common/Confirm";
export default {
  name: 'LockupHistoryList',

  components: {
    Pagination,
    Confirm
  },

  data() {
    return {
      selectedState: this.$route.params.ss ? this.$route.params.ss : '',
      selectedSearchType: this.$route.params.email ? 'EMAIL' : '',
      searchKeyword: this.$route.params.email ? this.$route.params.email : '',
      size: '10',
      page:this.$route.params.page ? this.$route.params.page : 1,
      total: 0,
      items: [],
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'LockupHistory', this.$options.name)
  },

  mounted() {
    let page = this.$route.query.page
    if(typeof(page) === 'undefined') {
      page = 1
    }

    let size = this.$route.query.size
    if(typeof(size) !== 'undefined') {
      this.size = size
    }

    let ss = this.$route.query.ss
    if(typeof(ss) !== 'undefined') {
      this.selectedState = ss
    }

    let st = this.$route.query.st
    if(typeof(st) !== 'undefined') {
      this.selectedSearchType = st
    }

    let sk = this.$route.query.sk
    if(typeof(sk) !== 'undefined') {
      this.searchKeyword = sk
    }

    this.getList(page)
  },

  methods: {
    addUserLockupPlan(item) {
      this.$router.push({ name: 'UserAddLockupPlan', params: { userIdx: item.userIdx } })
    },

    trans(value) {
      if(value === 'LOCK') {
        return '락업중'
      } else if(value === 'PROCESSING') {
        return '지급중'
      } else if(value === 'CANCEL') {
        return '취소'
      } else if(value === 'DONE') {
        return '지급완료'
      }
    },

    terminatePlan(lockupIdx) {
      this.$refs.Confirm.showDialog(`락업 플랜을 해지 하시겠습니까?<br>※ 락업 종료 후 지급 중인 경우 금일 이후로 지급이 중단됩니다.`, () => {
        let formData = new FormData()
        formData.append('lockupIdx', lockupIdx)

        this.$post(this.$LOCKUP_PLAN_CANCEL, this.$options.name + 'terminatePlan', formData, true, (result) => {
          console.log(result)
          this.notify('success', '해지되었습니다.')
          this.getList(1)
        }, (result) => {
          console.log(result)
        })
      })
    },

    getList(page) {
      let params = new URLSearchParams()
      if(typeof page === 'undefined') page = 1
      params.append('page', page)
      params.append('limit', this.size)
      params.append('state', this.selectedState)
      params.append('criteria', this.selectedSearchType)
      params.append('keyword', this.searchKeyword.trim())

      this.$get(this.$LOCKUP_HISTORY + '?' + params.toString(), this.$options.name + '_getList', true, (result) => {
        this.page = page
        this.total = result.total
        try {
          this.$refs.Pagination.init(this.$options.name, {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
          }, Number(this.page), this.total, Number(this.size))
        } catch (e) {
          console.log(e)
        }
        this.$router.replace({
          query: {
            ss: this.selectedState,
            st: this.selectedSearchType,
            sk: this.searchKeyword,
            page: this.page,
            size: this.size,
          },
        });
        this.items = result.data
        console.log(this.items)

        this.items.forEach(item => {
          item.availableBalance = Number(item.lockupBalance) - Number(item.remain)
        })
      }, (result) => {
        this.httpError(result)
      })
    },
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },

    'selectedState'() {
      this.page = 1
    },

    'selectedSearchType'() {
      this.page = 1
    },

    'searchKeyword'() {
      this.page = 1
    },
  },
}
</script>

<style>
th, td{
  text-align: center;
  word-break: keep-all;
}
.table thead th {
  vertical-align: middle;
}
.table td {
  vertical-align: middle;
}
</style>

<template>
  <div class="login-page dark-mode">
    <div class="login-box">
      <div class="login-logo">
        <a><b>{{ getTitle() }}</b></a>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">관리자 로그인</p>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model="email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-id-badge"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model="password" @keyup.enter="authOTP()">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="social-auth-links text-center mb-3" v-if="!otpOn">
            <a class="btn btn-block btn-primary" @click="authOTP()">
              OTP 인증
            </a>
          </div>
          <div v-else>
            <div class="input-group mb-3">
              <input type="password" class="form-control" ref="cursor" placeholder="OTP" v-model="otpCode" @keyup.enter="login()">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="social-auth-links text-center mb-3">
              <a class="btn btn-block btn-primary" @click="login">
                로그인
              </a>
            </div>
            <div class="social-auth-links text-center mb-3">
              <router-link :to="{ name: 'OTPSetting', params: { twoFactorKey: twoFactorKey } }" class="btn btn-block btn-primary">
                OTP 등록
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      otpOn: false,
      otpCode: '',

      verifyCode: '',
      authMethod: '',
      twoFactorKey: '',
    }
  },
  created() {
    sessionStorage.clear()
  },

  methods: {
    authOTP() {
      if (!this.email || !this.password) {
        this.notify('error', '이메일과 비밀번호를 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      this.$post(this.$ADMIN_LOGIN, 'post_admin_login', formData, false, (result) => {
        let data = result.data[0]
        this.twoFactorKey = data.twoFactorKey
        if(!data.isTwoFactor) {
          this.notify('error', 'OTP를 등록해주세요.')
        }
        this.otpOn = true
        setTimeout(() => { this.$refs.cursor.focus() }, 300)
      }, (result) => {
        console.log(result)
        this.notify('error', '잘못된 데이터입니다.')
      })
    },

    login() {
      if (!this.otpCode) {
        this.notify('error', 'OTP 인증 코드를 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('otpCode', this.otpCode)
      formData.append('twoFactorKey', this.twoFactorKey)

      this.$post(this.$ADMIN_LOGIN_OTP, 'post_admin_login_otp', formData, true, (result) => {
        sessionStorage.setItem('token', result.data[0].accessToken)
        sessionStorage.setItem('refreshToken', result.data[0].refreshToken)
        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)
        this.$router.replace({ name: 'Dashboard' })
      }, (result) => {
        console.log(result)
      })
    }
  }
}
</script>

<style>
</style>

<template>
  <div class="m-3">
    <div class="card-header border-bottom-0">
      <h5 class="card-title">개인정보</h5>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>No.</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control disabled" disabled :value="item ? item.userIdx: ''" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>이름</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.name" />
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>이메일</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.email" />
      </div>
    </div>
<!--    <div class="row align-items-center mb-2">-->
<!--      <div class="col-sm-3 text-right">-->
<!--        <strong>이메일 인증</strong>-->
<!--      </div>-->
<!--      <div class="col-sm-6">-->
<!--        <select class="form-control" disabled :value="data.isEmailAuth">-->
<!--          <option value="true">인증됨</option>-->
<!--          <option value="false">인증되지 않음</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      </div>-->
<!--      <div class="row align-items-center mb-2">-->
<!--        <div class="col-sm-3 text-right">-->
<!--          <strong>휴대폰</strong>-->
<!--        </div>-->
<!--        <div class="col-sm-6">-->
<!--          <input type="text" class="form-control" disabled :value="data.phone" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row align-items-center mb-2">-->
<!--        <div class="col-sm-3 text-right">-->
<!--          <strong>휴대폰 인증</strong>-->
<!--        </div>-->
<!--        <div class="col-sm-6">-->
<!--          <select class="form-control" disabled :value="data.isPhoneAuth">-->
<!--            <option value="true">인증됨</option>-->
<!--            <option value="false">인증되지 않음</option>-->
<!--          </select>-->
<!--        </div>-->
<!--      </div>-->
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>지갑 주소</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.address" :key="rerenderKey"/>
      </div>
      <button class="badge btn btn-primary ml-2" @click="$router.push({ name: 'WalletList', params: { email: item.email } })">입출금 내역 보기</button>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>락업 잔고</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="$numberWithCommasAndDecimal(item.lockupBalance)" />
      </div>
      <button class="badge btn btn-primary ml-2" @click="$router.push({ name: 'LockupHistoryList', params: { email: item.email } })">락업 내역 보기</button>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-sm-3 text-right">
        <strong>사용 가능한 잔고</strong>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control" disabled :value="item.availableBalance" />
      </div>
    </div>
<!--    <div class="row align-items-center mb-2">-->
<!--      <div class="col-sm-3 text-right">-->
<!--        <strong>출금상태</strong>-->
<!--      </div>-->
<!--      <div class="col-sm-6">-->
<!--        <input type="text" class="form-control" disabled :value="item.withdrawState" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "UserDetailInfo",

  props: [
      'userIdx',
  ],

  data() {
    return {
      rerenderKey: 0,
      item: [],
    }
  },

  created() {
    if(this.item) {
      this.getData()
    } else {
      this.$router.back()
    }
  },

  methods: {
    getData() {
      this.$get(`${this.$USER_LIST}/${this.userIdx}`, this.$options.name + '_getData', true, (result) => {
        this.item = result.data[0]
        this.rerenderKey++
        console.log(result.data[0])
      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <!--            <h4 class="m-0">락업 플랜 추가</h4>-->
            <!--            <span> 데이터베이스에 기록된 관리자 목록을 확인합니다.</span>-->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">락업 플랜 등록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>이름 *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="formData.lockupPlanName"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>락업기간(일수) *</strong>
                  </div>
                  <div class="col-sm-6">
                    <input type="number" class="form-control" v-model="formData.lockupPlanPeriod"/>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3 text-right">
                    <strong>지급비율 *</strong>
                  </div>
                  <div class="col-sm-3">
                    <input type="number" class="form-control" v-model="formData.lockupPlanRatio"/>
                    <div class="input_placeholder">
                      <span>%</span>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <input type="number" class="form-control" v-model="formData.lockupLatency"/>
                    <div class="input_placeholder">
                      <span>Day</span>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center mb-2">
                  <div class="col-sm-3">
                  </div>
                  <div class="col-sm-6">
                    <span>※ 균등 지급을 위해 지급비율을 100으로 나누었을 시 몫이 정수가 되는 값을 입력해주세요.</span>
                  </div>
                </div>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix text-center row">
                <div class="col-sm-3">
                </div>
                <div class="col-sm-5">
                  <button type="button" class="btn btn-primary mr-1" @click="register">등록</button>
                  <button type="button" class="btn btn-secondary" @click="$router.back()">목록</button>
                </div>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LockupPlanRegister',
  data() {
    return {
      formData: {
        lockupPlanName: '',
        lockupPlanPeriod: 0,
        lockupPlanRatio: 0,
        lockupLatency: 0,
      },
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'lockupPlan', this.$options.name)
  },

  methods: {
    register() {
      if(100 % this.formData.lockupPlanRatio) {
        this.notify('error', '지급비율을 다시 입력해주세요.')
        return
      }

      let formData = new FormData()

      let stop = false
      Object.entries(this.formData).forEach((entry) => {
        let [key, value] = entry
        if(!stop && !value) {
          this.notify('error', '정확히 입력해주세요.')
          stop = true
          return
        }
        formData.append(key, key === 'lockupPlanRatio' ? (100 / this.formData.lockupPlanRatio) : (value+'').trim())
      })

      if(stop) {
        return
      }

      this.$post(this.$LOCKUP_PLAN_REGISTER, this.$options.name + '_setData', formData, true,  () => {
        this.notify('success', '등록되었습니다.')
        this.formData = {
          lockupPlanName: '',
          lockupPlanPeriod: 0,
          lockupPlanRatio: 0,
          lockupLatency: 0,
        }
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
.input_placeholder{
  position: absolute;
  top: 16%;
  right: 10%;
}
</style>

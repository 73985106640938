import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Intro from "@/components/Intro"



import Dashboard from "@/components/dashboard/Dashboard"
import OTPSetting from "@/components/OTPSetting"
import Login from "@/components/Login"
import UserDetail from "@/components/user/UserDetail"
import UserList from "@/components/user/UserList"
import WhiteList from "@/components/user/WhiteList"
import LockupHistoryList from "@/components/lockupHistory/LockupHistoryList"
import LockupHistoryPayment from "@/components/lockupHistory/LockupHistoryPayment"
import UserAddLockupPlan from "@/components/user/UserAddLockupPlan"
import LockupPlanList from "@/components/lockupPlan/LockupPlanList"
import LockupPlanRegister from "@/components/lockupPlan/LockupPlanRegister"
import CurrencyInternal from "@/components/currency/CurrencyInternal"
import CurrencyExternal from "@/components/currency/CurrencyExternal"
import CurrencyOutput from "@/components/currency/CurrencyOutput"
import WalletList from "@/components/wallet/WalletList"

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Intro',
            component: Intro,
            meta: {
                hideLNBHeader: true
            },
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                hideLNBHeader: true
            },
        },

        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
        },
        //etc
        {
            path: '/otp/setting',
            name: 'OTPSetting',
            component: OTPSetting,
        },


        {
            path: '/user/detail/:userIdx',
            name: 'UserDetail',
            component: UserDetail,
        },

        {
            path: '/user/list',
            name: 'UserList',
            component: UserList,
        },
        {
            path: '/white/list',
            name: 'WhiteList',
            component: WhiteList,
        },
        {
            path: '/user/add/lockup/plan/:userIdx',
            name: 'UserAddLockupPlan',
            component: UserAddLockupPlan,
        },

        {
            path: '/lockup/plan/list',
            name: 'LockupPlanList',
            component: LockupPlanList,
        },
        {
            path: '/lockup/plan/register',
            name: 'LockupPlanRegister',
            component: LockupPlanRegister,
        },
        {
            path: '/currency/internal',
            name: 'CurrencyInternal',
            component: CurrencyInternal,
        },
        {
            path: '/currency/external',
            name: 'CurrencyExternal',
            component: CurrencyExternal,
        },
        {
            path: '/currency/output',
            name: 'CurrencyOutput',
            component: CurrencyOutput,
        },
        {
            path: '/wallet/list',
            name: 'WalletList',
            component: WalletList,
        },
        {
            path: '/lockup/history/list',
            name: 'LockupHistoryList',
            component: LockupHistoryList,
        },
        {
            path: '/lockup/history/payment',
            name: 'LockupHistoryPayment',
            component: LockupHistoryPayment,
        },
    ],
    strict: true
})

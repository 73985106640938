<template>
  <nav class="main-header navbar navbar-expand navbar-dark">
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item d-none d-sm-inline-block">
        <a @click="logout" class="nav-link text-orange point">
          <p><i class="fas fa-sign-out-alt"></i>로그아웃</p>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  create() {

  },

  methods: {
    logout() {
      this.$root.$emit(this.$EVENT_LNB_CHANGE, null, null)

      this.$get(this.$ADMIN_LOGOUT, 'HeaderLogout', true, (result) => {
        console.log(result)
        sessionStorage.clear()
      }, (result) => {
        console.log(result)
      })

      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style>
</style>
